@use "../variables" as *;

// FOOTER SECTION ------------------------------------

footer {
  position: relative;
  bottom: 0;
  background-color: $grey;
}

.footer-container {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer p {
  position: relative;
  font-size: $fs-default;
  font-weight: $fw-semi-bold;
  font-family: $ff-headings;
  margin-right: 2%;
  color: $black;

  &::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 25px;
    top: 8%;
    right: -8%;
    background-color: $pink;
  }
}

.footer-social-icon-box {
  padding: .5em;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
}

.footer-social-icon {
  font-size: $fs-med;
}