@use "../variables" as *;

// CONTACT SECTION -----------------------------------

.contact-section {
  background-color: $pink;
  position: relative;
  min-height: calc(100vh - 55px);
  padding: 10% 0;
}

.contact-outer-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contact-left-box {
  flex-basis: 40%;
}

.contact-right-box {
  flex-basis: 60%;

  a {
    margin-left: 10%;
  }
}

.contact-body {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  padding: 3% 0 8% 0;
}

.contact-header {
  padding-bottom: 8%;
}

.contact-icon-box {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.social-icon-box {
  border: 2px solid $transparent;
  padding: 0;
  margin: 0 1%;

  &:hover,
  &:focus {
    border: 2px solid $black;
  }
}

.social-icon {
  margin: .2em;
  padding: .2em .2em .1em .2em;
  color: $black;
  width: 2em;
  height: 2em;
  transition: 300ms;
}

.contact-form {
  display: flex;
  flex-direction: column;
  padding-left: 5%;

  label {
    margin: 2% 0 1%;
    font-weight: $fw-semi-bold;
  }
}

.client-name,
.client-email,
.client-message {
  padding: 2%;
  background-color: $white;
  border: $white;
}

.form-button {
  align-self: flex-start;
  margin-top: 2%;
}