@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Rubik:wght@300&display=swap');

// Colours
$transparent: #00000000;
$black: #2D2D2D;
$white: #FFFFFF;
$red: #CF4027;
$blush: #E0807E;
$pink: #F1CED4;
$yellow: #F1A931;
$grey: #F3F3F3;
// Fonts Sizes
$fs-global: 62.5%;
$fs-4xl: 10rem; 
$fs-3xl: 8rem; 
$fs-2xl: 6rem;
$fs-xl: 4.8rem;
$fs-lrg: 2.9rem;
$fs-med: 2.2rem;
$fs-small: 2rem;
$fs-xs: 1.8rem;
$fs-default: 1.6rem;

// Font Weights
$fw-default: 300;
$fw-semi-bold: 400;
$fw-bold: 300;

// Font Family
$ff-headings: 'Rubik', sans-serif; 
$ff-body: 'Lato', sans-serif;

