@use "./variables" as *;

// Global Typography
html {
    font-size: $fs-global;
    color: $black;
}

h1,
h2,
h4,
h5 {
    font-family: $ff-headings;
    color: $black;
}

h1 {
    font-size: $fs-lrg;
    text-transform: uppercase;
    letter-spacing: -.05em;

}

h3 {
    font-size: $fs-xl;
    font-weight: $fw-bold;
    font-family: $ff-headings;
    color: $black;
}

h2 {
    font-size: $fs-3xl;
    margin-bottom: 5%;
    text-transform: uppercase;
    letter-spacing: -.05em;
}

h4 {
    font-size: $fs-med;
    font-weight: $fw-bold;
}

h5 {
    font-size: $fs-small;
    margin: 1% 0;
}

p,
label,
textarea,
li,
input[type="email"],
input[type="text"] {
    font-family: $ff-body;
    font-weight: $fw-default;
    font-size: $fs-xs;
    color: $black;
}

a,
button,
.ff-logo {
    font-family: $ff-headings;
    font-weight: $fw-semi-bold;
    font-size: $fs-small;
    color: $black;
}

p,
label,
textarea,
li,
input[type="email"],
input[type="text"] {
    line-height: 3rem;
}

a,
button {
    text-decoration: none;
    border: 2px solid $transparent;
}

label,
button,
.nav-link {
    font-weight: $fw-default;
}

.nav-link {
    text-transform: uppercase;
    font-size: $fs-default;
}

i {
    color: $blush;
    font-size: $fs-4xl;
}

ul {
    list-style-type: none;
}