@use "../variables" as *;

// ABOUT ME SECTION -----------------------------------

.about-me-section {
  padding: 100px 0 3%;
  background-color: $white;
    min-height: 100vh;
}

.about-me-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  // min-height: 85vh;
}

.about-me-img-container {
  align-self: center;
  flex: 40%;
  // margin-top: 10px;
}

.fiona-portrait {
  object-position: 45%;
  object-fit: cover;
  width: 90%;
  min-width: 280px;
  padding: 2%;
  // border-radius: 50% 50% 0 0;
  // border: 3px solid $pink;
  // box-shadow: 5px 5px 0px $pink;
}

.about-me-heading {
  color: $black;
  margin-bottom: 3%;
}

.about-me-h4 {
  margin: 3% 0 2%;
}

.about-me-text-container {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 60%;
  flex-wrap: wrap;
  padding: 0 2%;
  margin-right: 5%;
}

.about-me-text {
  padding-bottom: 2%;
}

.about-titles {
  margin-bottom: 3%;
}

.bold-link {
  font-family: $ff-body;
  font-weight: $fw-semi-bold;
}

.juno-link {
  font-family: $ff-body;
  font-weight: $fw-semi-bold;
  border-bottom: 1px solid $black;

  &:hover,
  &:focus {
    border-bottom: 1px solid $red;
  }
}

.scroll-btn {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-basis: 100%;
padding: 0 0 4em;

  a {
    width: 40px;
    height: 40px;
  }
}

.down-arrow-btn {
  width: 2em;
  height: 2em;
  transition: 300ms;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
}