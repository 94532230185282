@use "./variables" as *;

// Global Styles
.app {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

html,
body,
h1,
h2,
h3,
h4,
p,
a,
ul {
	padding: 0;
	margin: 0;
}

nav ul {
	list-style: none;
}

textarea {
	resize: none;
}

img {
	height: auto;
	width: 100%;
}

.wrapper {
	max-width: 1200px;
	width: 85%;
	margin: 0 auto;
}
.nav-wrapper {
	max-width: 1200px;
	width: 88.5%;
	margin: 0 auto;
}

main {
	background-color: $white;
}

html {
	scroll-behavior: smooth;
}

// Global Button/Link Styles -------------------

.button-outline,
.link-outline {
	display: inline-block;
	padding: 15px 30px;
	color: $white;
	background-color: $black;
	border: 2px solid $black;
	transition: 500ms;
}


.button-outline:hover,
.button-outline:focus,
.link-outline:hover,
.link-outline:focus {
	color: $black;
	background-color: $white;
	border: 2px solid $black;
}

.button-outline:active,
.link-outline:active {
	scale: 0.9;
}

.body-link {
	font-family: $ff-body;
	font-size: $fs-xs;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

// Global Shape Styles

.shape {
	position: absolute;
	width: 125px;
	height: 129px;
}

.arrow {
	position: absolute;
	width: 75px;
	height: 79px;
}