@use "../variables" as *;

// PROJECTS SECTION ----------------------------------
.projects-section {
  min-height: calc(100vh - 50px);
  padding: 10% 0 3%;
  background-color: $white;
}

.proj-heading {
  color: $black;
}

.proj-outer-box {
  position: relative;
}

.proj-container {
  padding: 2% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

//  PROJECT BOX STYLES----------
.proj-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  margin: 2% 0;
  border: 3px solid $grey;
  transition: 300ms;

  &:hover {
      box-shadow: 0px 0px 10px 5px rgba(170, 170, 170, 0.52);;
  }
}

.proj-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5% 5% 1% ;
  min-height: 250px;
}

.vote-proj-text {
  min-height: 270px;
}


.proj-link-container {
  padding: 1% 5% 5%;
}

.proj-desc,
.proj-skills {
  margin: 3% 0 2% 0;
}

.proj-skills {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5%;

  li {
    padding-right: 2%;
    text-transform: uppercase;
    font-weight: $fw-semi-bold;
    font-size: $fs-xs;
    color: $black;
  }
}

.proj-link:first-of-type {
  margin-right: 5%;
}

// PROJECT IMAGE STYLES ----------
.proj-img-container {
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-basis: 32%;
  margin: -2px -2px;

}

.creative-img,
.animal-match-img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  position: relative;
  transition: 300ms;
}

.creative-img:hover,
.animal-match-img:hover,
.creative-img:focus,
.animal-match-img:focus {
  background-color: $pink;
}