@use "../variables" as *;

// HOME PAGE -----------------------------------

.home-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 121px);
  margin-top: 70px;
  background-color: $white;

  h2 {
    margin-bottom: 0.4em;
    &::after {
      content: "";
      display: block;
      text-align: left;
      width: 90%;
      height: 2px;
      background-color: $pink;
    }
  }

  h3 {
    font-family: $ff-body;
    font-size: $fs-lrg;
    margin-bottom: 1.5em;
  }
}

.heading-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
  max-height: 600px;
}

.heading-img-cont {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 600px;
  margin-left: auto;

  .desktop-img {

    object-position: 20% 70%;
    object-fit: cover;
    width: 450px;
    height: 600px;
    padding: 2%;
    border-radius: 40% 40% 0 0;
  }
}

// Home Page Shapes
.home-pie {
  right: 6%;
  top: 1%;
}

.home-soft-square {
  left: -5%;
  bottom: 10%;
}

.home-arrow-point {
  left: 10%;
  top: 5%;
  rotate: 45deg;
}

.home-arrow-square {
  right: 5%;
  bottom: 8%;
  rotate: -46deg;
}