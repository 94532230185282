@use "../variables" as *;

// SKILLS SECTION ------------------------------------

.skills-section {
  background-color: $pink;
  padding: 10% 0 15%;

  h3,
  p {
    color: $black;
  }
}

.skills-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.skills-icon-container {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  flex-wrap: wrap;
  padding: 0% 1%;
  transition: 500ms;
}

.icon-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-basis: 25%;
  min-height: 130px;
  min-width: 130px;
  margin-top: 2%;
}

.brand-icon {
  height: auto;
  min-width: 10rem;
  padding: 20px 10px;
  color: $black;
  transition: 300ms;
  &:hover {
    opacity: 0.7;
  }
}